<template>
  <div class="view-dummy">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
  export default {
    props: ['name'],
    computed: {
      text() {
        if (this.name) {
          return this.$t(`dummy-view.${this.name}`)
        }
        return this.$route.name
          ? this.$t(`dummy-view.${this.$route.name}`)
          : this.$route.path
      }
    }
  }
</script>

<style lang="scss" scoped>
  .view-dummy {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-family: Impact, Haettenschweiler, Franklin Gothic Bold, Charcoal,
        Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
      font-size: 42px;
      color: #c5c8ce;
      text-transform: uppercase;
      text-align: center;
      max-width: 50%;
    }
  }
</style>
