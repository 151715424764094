<template>
  <div>
    <Header class="header">
      <div class="header-content">
        <div class="title">
          <slot name="title" class="title">
            <Icon v-if="icon" :type="icon" size="20"></Icon>
            <span>{{ title }}</span>
          </slot>
        </div>
        <div class="filler"></div>
        <div class="controls">
          <slot name="controls"></slot>
        </div>
      </div>
    </Header>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['icon', 'title']
  }
</script>

<style lang="scss">
  .header-content .controls {
    white-space: nowrap;
    > div,
    > button,
    > input {
      margin-left: 0.5em;
    }
  }
</style>

<style lang="scss" scoped>
  .header {
    background-color: #fff;
    padding: 0 1rem;
  }

  .header-content {
    display: flex;
    align-items: center;

    .title {
      font-size: 1.4em;
    }

    .filler {
      flex: 1;
    }

    .controls {
      display: flex;
      align-items: center;
    }
  }

  .content {
    margin: 1rem;
    margin-right: 0;
  }
</style>
