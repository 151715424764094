<template>
  <ViewLayout icon="md-paper" :title="$t('label.licenses')">
    <template #controls>
      <header-select-group
        v-if="is_admin"
        v-model="group"
      ></header-select-group>

      <HeaderSearchtext
        v-model="searchtext"
        :label="$t('licenses.searchtext')"
      />

      <Button type="default" icon="ios-download-outline" @click="download">
        {{ $t('action.export') }}
      </Button>
    </template>

    <LicenseList
      ref="licenses"
      :group="group"
      :search-text="searchtext"
      @set-search="setSearch"
    />
  </ViewLayout>
</template>

<script>
  import HeaderSelectGroup from '../components/header-select-group'
  import HeaderSearchtext from '../components/header-searchtext'
  import LicenseList from '../components/license-list'
  import CommonMixin from '../mixins/common'

  export default {
    components: {
      LicenseList,
      HeaderSelectGroup,
      HeaderSearchtext
    },
    mixins: [CommonMixin],
    computed: {
      searchtext: {
        get() {
          return this.$store.state.ui.licenses_searchtext
        },
        set(v) {
          this.$store.commit('ui/setLicensesSearchtext', v)
        }
      },
      group: {
        get() {
          return this.$store.state.ui.licenses_group
        },
        set(v) {
          this.$store.commit('ui/setLicensesGroup', v)
        }
      }
    },
    methods: {
      setSearch(v) {
        this.searchtext = v
      },
      download() {
        this.$refs.licenses.export()
      }
    }
  }
</script>
