//-----------------------------------------------------------------------------

require('iview/dist/styles/iview.css')

//-----------------------------------------------------------------------------

require('../common/bootstrap')

//-----------------------------------------------------------------------------

import _ from 'lodash'
import Vue from 'vue'

//-----------------------------------------------------------------------------

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-US',
  messages: {
    'en-US': require('./assets/strings/en-US.json')
  }
})

//-----------------------------------------------------------------------------

import iView from 'iview'
import iView_locale from 'iview/dist/locale/en-US'

iView_locale.i.datepicker.weekStartDay = '1'

Vue.use(iView, {
  transfer: true,
  locale: iView_locale
})

//-----------------------------------------------------------------------------

import objectHash from 'object-hash'

Vue.prototype.$objectHash = function (o) {
  return objectHash(o)
}

//-----------------------------------------------------------------------------

Vue.prototype.$reportError = function (err) {
  if (!err) {
    return
  }
  const code = _.get(err, 'code', 'internal-error')
  const message = _.get(err, 'message', _.toString(err))

  let title = `error.${_.toString(code)}`
  title = this.$te(title) ? this.$t(title) : code
  this.$Notice.error({
    duration: 5.0,
    title: this.$t('label.error'),
    desc: message ? `${title}: ${message}` : title
  })
}

//-----------------------------------------------------------------------------

import store from './store'

//-----------------------------------------------------------------------------

import API from './services/api'

if (!process.env.API_BASEURL) {
  throw new Error('API_BASEURL not set')
}

const api = (Vue.prototype.$api = new API({
  baseURL: process.env.API_BASEURL
}))

const updateLoading = () => store.commit('setLoading', api.hasOpenRequests)
api.on('request-started', updateLoading)
api.on('request-successful', updateLoading)
api.on('request-failed', updateLoading)

//-----------------------------------------------------------------------------

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import ViewLogin from './views/login'
import ViewDummy from './views/dummy'
import ViewCustomers from './views/customers'
import ViewCustomer from './views/customer'
import ViewLicenses from './views/licenses'
import ViewDashboard from './views/dashboard'
import ViewStatistics from './views/statistics'
import ViewUsers from './views/users'
import ViewUser from './views/user'
import ViewGroups from './views/groups'
import ViewGroup from './views/group'
import ViewProducts from './views/products'
import ViewProduct from './views/product'
import ViewOrders from './views/orders'
import ViewOrder from './views/order'

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'login',
      path: '/login',
      component: ViewLogin,
      meta: { public: true }
    },
    { name: 'dashboard', path: '/', component: ViewDashboard },
    { name: 'customers', path: '/customers', component: ViewCustomers },
    {
      name: 'customer',
      path: '/customer/:id',
      component: ViewCustomer,
      props: true,
      meta: { nav: 'customers' }
    },
    { name: 'licenses', path: '/licenses', component: ViewLicenses },
    { name: 'settings', path: '/settings', component: ViewDummy },
    { name: 'support', path: '/support', component: ViewDummy },
    { name: 'statistics', path: '/statistics', component: ViewStatistics },
    { name: 'users', path: '/users', component: ViewUsers },
    {
      name: 'user',
      path: '/user/:id?',
      component: ViewUser,
      props: true,
      meta: { nav: 'users' }
    },
    { name: 'groups', path: '/groups', component: ViewGroups },
    {
      name: 'group',
      path: '/group/:id?',
      component: ViewGroup,
      props: true,
      meta: { nav: 'groups' }
    },
    { name: 'products', path: '/products', component: ViewProducts },
    {
      name: 'product',
      path: '/product/:id?',
      component: ViewProduct,
      props: true,
      meta: { nav: 'products' }
    },
    { name: 'orders', path: '/orders', component: ViewOrders },
    {
      name: 'order',
      path: '/order/:id?',
      component: ViewOrder,
      props: true,
      meta: { nav: 'orders' }
    },
    { path: '*', redirect: '/' }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(function (to, _from, next) {
  if (to.name === 'login') {
    return next()
  }
  api
    .refresh()
    .then((user) => {
      store.commit('auth/setUser', user)
      next()
    })
    .catch(() => {
      store.commit('auth/setUser', null)
      next(
        to.meta.public
          ? undefined
          : `/login?redirect=${encodeURIComponent(to.fullPath)}`
      )
    })
})

//-----------------------------------------------------------------------------

import ViewLayout from './components/view-layout'
Vue.component('ViewLayout', ViewLayout)

//-----------------------------------------------------------------------------

import App from './app'

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app')
