<template>
  <ViewLayout icon="md-person" :title="$t('label.user')">
    <template #controls>
      <ButtonGroup>
        <Button
          :type="is_pristine ? 'default' : 'primary'"
          icon="md-cloud-upload"
          :disabled="!can_save"
          @click="save()"
        >
          {{ $t('action.save') }}
        </Button>
        <Button
          v-if="!is_creating"
          type="error"
          icon="md-trash"
          ghost
          @click="remove()"
        >
          {{ $t('action.delete') }}
        </Button>
      </ButtonGroup>
    </template>
    <template v-if="user">
      <Form :model="user" :label-width="160">
        <Row>
          <i-col span="12">
            <FormItem
              :label="`${$t('label.email')} / ${$t('label.username')}`"
              prop="email"
            >
              <i-input v-model.trim="user.email" :disabled="!is_creating" />
            </FormItem>
            <FormItem :label="$t('label.password')" prop="password">
              <i-input v-model.trim="user.password" />
            </FormItem>
            <FormItem>
              <Alert
                v-if="user.password && user.password.length"
                type="info"
                show-icon
              >
                {{ $t('users.hint-password-constraints') }}
              </Alert>
            </FormItem>
            <FormItem v-if="!is_creating">
              <Alert type="warning" show-icon>
                {{ $t('users.hint-change-password') }}
              </Alert>
            </FormItem>
            <FormItem :label="$t('label.group')" prop="group">
              <Select v-model="user.group">
                <Option value="">&mdash;</Option>
                <Option v-for="g in groups" :key="g._id" :value="g._id">
                  {{ g.name }} ({{ g.request_code }})
                </Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('label.administrator')" prop="administrator">
              <i-switch v-model="user.admin" />
            </FormItem>
            <FormItem v-if="user.admin">
              <Alert type="warning" show-icon>
                {{ $t('users.hint-administrator-flag') }}
              </Alert>
            </FormItem>
            <FormItem :label="$t('users.cms-access')" prop="cms_rights">
              <i-switch v-model="cms_rights_enabled" />
            </FormItem>
            <FormItem
              v-if="cms_rights_enabled"
              :label="$t('users.cms-rights')"
              prop="cms_rights"
            >
              <div v-for="k in cms_rights_keys" :key="k">
                <Checkbox v-model="user.cms_rights[k]">
                  {{ $t(`users.cms_rights.${k}`) }}
                </Checkbox>
              </div>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </template>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'

  const DEFAULT_CMS_RIGHTS = {
    create_and_delete_datasets: false,
    modify_datasets: true,
    export_datasets: true,
    create_and_delete_nodes: true,
    modify_nodes: true,
    create_and_delete_strings: true,
    modify_strings: true
  }

  export default {
    mixins: [APIMixin],
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        user: null,
        pristine_hash: '',
        original_email: '',
        groups: []
      }
    },
    computed: {
      is_creating() {
        return !this.id
      },
      can_save() {
        if (!this.user || !_.trim(this.user.email) || !this.user.group) {
          return false
        }
        const pwdlen = _.size(this.user.password)
        return this.is_creating ? pwdlen >= 8 : !pwdlen || pwdlen >= 8
      },
      cms_rights_enabled: {
        get() {
          return _.isObject(this.user && this.user.cms_rights)
        },
        set(v) {
          this.user.cms_rights = v ? _.clone(DEFAULT_CMS_RIGHTS) : null
        }
      },
      cms_rights_keys() {
        return _.keys(DEFAULT_CMS_RIGHTS)
      },
      is_pristine() {
        return this.pristine_hash === this.$objectHash(this.user)
      }
    },
    watch: {
      id() {
        this.reload()
      }
    },
    mounted() {
      this.reload()
    },
    methods: {
      async reload() {
        this.user = await this.apiGet({ url: `/user/${this.id || ''}` })
        this.pristine_hash = this.$objectHash(this.user)
        this.original_email = this.user.email
        this.groups = await this.apiGet({ url: '/groups' })
      },
      async save() {
        const res = await this.apiPut({ url: `/user`, data: this.user })
        this.$Notice.success({
          title: this.$t('label.user'),
          desc: this.$t('notice.save-success')
        })
        if (this.is_creating) {
          this.$router.replace({ name: 'user', params: { id: res._id } })
        } else {
          this.reload()
        }
      },
      remove() {
        this.$Modal.confirm({
          title: this.$t('action.confirm'),
          content: this.$t('users.confirm-delete-message'),
          onOk: () => {
            this.apiDel({ url: `/user/${this.user._id}` }).then(() => {
              this.$router.replace({ name: 'users' })
            })
          }
        })
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.is_pristine) {
        return next()
      }
      this.$Modal.confirm({
        title: this.$t('action.confirm'),
        content: this.$t('confirm.unsaved-changes'),
        cancelText: this.$t('action.ignore'),
        onCancel: () => {
          next()
        },
        onOk: () => {
          next(false)
        }
      })
    }
  }
</script>

<style lang="scss" scoped></style>
