<template>
  <ViewLayout icon="md-folder-open" :title="$t('label.groups')">
    <template #controls>
      <ButtonGroup>
        <Button type="primary" icon="md-refresh" ghost @click="refresh">
          {{ $t('action.refresh') }}
        </Button>
        <Button type="primary" icon="md-add" @click="createGroup">
          {{ $t('action.create') }}
        </Button>
      </ButtonGroup>
    </template>

    <Table :data="results" :columns="columns">
      <template slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button icon="md-folder-open" @click="open(row)">
            {{ $t('action.edit') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
  </ViewLayout>
</template>

<script>
  import APIMixin from '../mixins/api'

  export default {
    mixins: [APIMixin],
    data() {
      return {
        results: [],
        columns: [
          {
            key: 'name',
            width: 320,
            sortable: true,
            title: this.$t('label.name')
          },
          {
            key: 'request_code',
            width: 320,
            sortable: true,
            title: this.$t('label.request-code')
          },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ]
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      async refresh() {
        this.results = []
        this.results = await this.apiGet('groups', { url: '/groups' })
      },
      open(group) {
        this.$router.push({ name: 'group', params: { id: group._id } })
      },
      createGroup() {
        this.$router.push({ name: 'group' })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
