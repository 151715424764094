<template>
  <ViewLayout icon="md-basket" :title="$t('label.orders')">
    <template #controls>
      <header-select-group
        v-if="is_admin"
        v-model="group"
      ></header-select-group>

      <HeaderSearchtext
        v-model="searchtext"
        :label="$t('orders.searchtext')"
        @search="refresh"
      />
    </template>

    <Table :data="results" :columns="columns">
      <template slot="group" slot-scope="{ row }">
        <router-link :to="{ name: 'group', params: { id: row.group } }">
          {{ $store.getters['groups/name_by_id'][row.group] || row.group }}
        </router-link>
      </template>
      <template slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button icon="md-folder-open" @click="open(row)">
            {{ $t('action.edit') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import { DateTime } from 'luxon'
  import APIMixin from '../mixins/api'
  import CommonMixin from '../mixins/common'
  import HeaderSelectGroup from '../components/header-select-group'
  import HeaderSearchtext from '../components/header-searchtext'
  import { toEur } from '../../common/utils'

  export default {
    components: {
      HeaderSelectGroup,
      HeaderSearchtext
    },
    mixins: [APIMixin, CommonMixin],
    data() {
      return {
        results: []
      }
    },
    computed: {
      columns() {
        return _.compact([
          {
            key: 'number',
            width: 180,
            sortable: true,
            title: this.$t('label.order-number')
          },
          {
            key: 'method',
            title: this.$t('label.payment-method')
          },
          {
            key: 'ordered',
            width: 200,
            sortable: true,
            title: this.$t('label.ordered'),
            render(h, params) {
              const d = DateTime.fromISO(params.row.ordered)
              return h('span', d.toFormat('dd.MM.yyyy HH:mm:ss'))
            }
          },
          {
            key: 'paid',
            width: 200,
            sortable: true,
            title: this.$t('label.paid'),
            render(h, params) {
              if (!params.row.paid) {
                return h('span', '-')
              }
              const d = DateTime.fromISO(params.row.paid)
              return h('span', d.toFormat('dd.MM.yyyy HH:mm:ss'))
            }
          },
          {
            key: 'total_price',
            title: this.$t('label.price'),
            render(h, p) {
              return h('span', toEur(p.row[p.column.key]).format())
            }
          },
          {
            key: 'bill',
            width: 160,
            title: this.$t('label.bill-number'),
            sortable: true
          },
          this.is_admin &&
            !this.group && {
              slot: 'group',
              key: 'group',
              sortable: true,
              title: this.$t('label.group')
            },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ])
      },
      group: {
        get() {
          return this.$store.state.ui.orders_group
        },
        set(v) {
          this.$store.commit('ui/setOrdersGroup', v)
        }
      },
      searchtext: {
        get() {
          return this.$store.state.ui.orders_searchtext
        },
        set(v) {
          this.$store.commit('ui/setOrdersSearchtext', v)
        }
      }
    },
    watch: {
      group() {
        this.refresh()
      }
    },
    mounted() {
      this.$store.dispatch('groups/refresh')
      this.refresh()
    },
    methods: {
      async refresh() {
        this.results = await this.apiGet('orders', {
          url: '/orders',
          params: {
            group: this.group,
            search: this.searchtext
          }
        })
      },
      open(order) {
        this.$router.push({ name: 'order', params: { id: order._id } })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
