<template>
  <div class="header-searchtext">
    <label>{{ label }}:</label>
    <Input
      v-model="text"
      search
      clearable
      placeholder="..."
      @on-clear="onSearch"
      @on-enter="onSearch"
      @on-search="onSearch"
    />
    <Button icon="ios-search" @click="onSearch"></Button>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        text: ''
      }
    },
    watch: {
      value(v) {
        this.text = v
      }
    },
    created() {
      this.text = this.value
    },
    methods: {
      onSearch() {
        this.$emit('input', this.text)
        this.$nextTick(() => {
          this.$emit('search', this.text)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-searchtext {
    display: flex;
    align-items: center;
  }

  label {
    margin-right: 0.5em;
  }
</style>
