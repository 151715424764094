<template>
  <div class="header-select-group">
    <div>{{ $t('label.group') }}:</div>
    <Select
      :value="value"
      :placeholder="$t('label.select-all')"
      style="width: 12em;"
      clearable
      @on-change="$emit('input', $event)"
    >
      <Option v-for="g of $store.state.groups.list" :key="g._id" :value="g._id">
        {{ g.name }}
      </Option>
    </Select>
  </div>
</template>

<script>
  import APIMixin from '../mixins/api'

  export default {
    mixins: [APIMixin],
    props: {
      value: {
        type: String,
        required: true
      }
    },
    created() {
      this.$store.dispatch('groups/refresh')
    }
  }
</script>

<style lang="scss" scoped>
  .header-select-group {
    display: flex;
    align-items: center;

    > div {
      margin-left: 0.5em;
    }
  }
</style>
