<template>
  <ViewLayout icon="md-person" :title="$t('label.customer')">
    <template #controls>
      <ButtonGroup>
        <Button
          :type="is_pristine ? 'default' : 'primary'"
          icon="md-cloud-upload"
          @click="save"
        >
          {{ $t('action.save') }}
        </Button>
        <!--<Button type="error" icon="md-trash" ghost @click="remove">{{ $t('action.delete') }}</Button>-->
      </ButtonGroup>
    </template>

    <Tabs v-if="customer" v-model="active_tab">
      <TabPane :label="$t('label.profile')" name="profile">
        <Form :model="customer" :label-width="80">
          <Row>
            <i-col span="10">
              <FormItem :label="$t('label.email')" prop="email">
                <i-input v-model.trim="customer.email" readonly disabled />
              </FormItem>
              <FormItem :label="$t('label.salutation')" prop="salutation">
                <Select v-model="customer.salutation">
                  <Option value="">&ndash;</Option>
                  <Option
                    v-for="s in available_salutations"
                    :key="s"
                    :value="s"
                  >
                    {{ $t(`customer-salutation.${s}`) }}
                  </Option>
                </Select>
              </FormItem>
              <FormItem :label="$t('label.title')" prop="title">
                <i-input v-model.trim="customer.title" />
              </FormItem>
              <FormItem :label="$t('label.firstname')" prop="firstname">
                <i-input v-model.trim="customer.firstname" />
              </FormItem>
              <FormItem :label="$t('label.lastname')" prop="lastname">
                <i-input v-model.trim="customer.lastname" />
              </FormItem>
              <FormItem :label="$t('label.institution')" prop="institution">
                <i-input v-model.trim="customer.institution" />
              </FormItem>
              <FormItem :label="$t('label.position')" prop="position">
                <i-input v-model.trim="customer.position" />
              </FormItem>
              <FormItem :label="$t('label.notes')" prop="notes">
                <i-input
                  v-model.trim="customer.notes"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                />
              </FormItem>
            </i-col>
            <i-col span="10">
              <FormItem :label="$t('label.phone')" prop="phone">
                <i-input v-model.trim="customer.phone" />
              </FormItem>
              <FormItem :label="$t('label.address')" prop="address">
                <i-input v-model.trim="customer.address" />
              </FormItem>
              <FormItem :label="$t('label.postcode')" prop="postcode">
                <i-input v-model.trim="customer.postcode" />
              </FormItem>
              <FormItem :label="$t('label.city')" prop="city">
                <i-input v-model.trim="customer.city" />
              </FormItem>
              <FormItem :label="$t('label.country')" prop="country">
                <i-input v-model.trim="customer.country" />
              </FormItem>
              <FormItem :label="$t('label.interest')" prop="country">
                <Select v-model="customer.interest">
                  <Option value="">&ndash;</Option>
                  <Option v-for="i in available_interests" :key="i" :value="i">
                    {{ $t(`customer-interest.${i}`) }}
                  </Option>
                </Select>
              </FormItem>
              <FormItem :label="$t('label.vat_id')" prop="vat_id">
                <i-input v-model.trim="customer.vat_id" />
              </FormItem>
              <FormItem :label="$t('label.order_no')" prop="order_no">
                <i-input v-model.trim="customer.order_no" />
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </TabPane>
      <TabPane :label="$t('label.licenses')" name="licenses">
        <LicenseList ref="licenses" :owner="customer._id" />
      </TabPane>
    </Tabs>
  </ViewLayout>
</template>

<script>
  import LicenseList from '../components/license-list'
  import APIMixin from '../mixins/api'

  export default {
    components: {
      LicenseList
    },
    mixins: [APIMixin],
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        active_tab: 'profile',
        customer: null,
        pristine_hash: ''
      }
    },
    computed: {
      available_salutations() {
        return ['mr', 'mrs']
      },
      available_interests() {
        return [
          'patient',
          'family-or-friend-of-patient',
          'student',
          'teacher',
          'medical-industry',
          'nurse',
          'medical-technician',
          'physician',
          'pharma-industry'
        ]
      },
      is_pristine() {
        return this.pristine_hash === this.$objectHash(this.customer)
      }
    },
    mounted() {
      this.reload()
    },
    methods: {
      async reload() {
        this.customer = await this.apiGet({ url: `/customer/${this.id}` })
        this.pristine_hash = this.$objectHash(this.customer)
        this.$nextTick(() => this.$refs.licenses.refresh())
      },
      async save() {
        await this.apiPut({ url: '/customer', data: this.customer })
        this.$Notice.success({
          title: this.$t('label.customer'),
          desc: this.$t('notice.save-success')
        })
        await this.reload()
      }
      /*
      remove() {
        this.$Modal.confirm({
          title: this.$t('action.confirm'),
          content: this.$t('customers.confirm-delete-message'),
          onOk: () => {
            this.apiDel({ url: `/customer/${this.customer._id}` })
              .then(() => this.$router.replace({ name: 'customers' }))
          }
        })
      }
      */
    },
    beforeRouteLeave(to, from, next) {
      if (this.is_pristine) {
        return next()
      }
      this.$Modal.confirm({
        title: this.$t('action.confirm'),
        content: this.$t('confirm.unsaved-changes'),
        cancelText: this.$t('action.ignore'),
        onCancel: () => {
          next()
        },
        onOk: () => {
          next(false)
        }
      })
    }
  }
</script>
