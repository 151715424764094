const state = {
  user: null
}

const mutations = {
  setUser(state, user) {
    state.user = user || null
  }
}

export default { namespaced: true, state, mutations }
