import currency from 'currency.js'

export function toEur(v) {
  return currency(v, {
    separator: ' ',
    decimal: ',',
    symbol: '€',
    formatWithSymbol: true
  })
}
