<template>
  <ViewLayout icon="md-people" :title="$t('label.customers')">
    <template #controls>
      <header-select-group
        v-if="is_admin"
        v-model="group"
      ></header-select-group>
      <HeaderSearchtext
        v-model="searchtext"
        :label="$t('customers.searchtext')"
        @search="refresh"
      />
      <Button type="default" icon="ios-download-outline" @click="download">
        {{ $t('action.export') }}
      </Button>
    </template>

    <Table :data="results" :columns="columns">
      <template slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button icon="md-folder-open" @click="open(row)">
            {{ $t('action.edit') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
  </ViewLayout>
</template>

<script>
  import HeaderSelectGroup from '../components/header-select-group'
  import HeaderSearchtext from '../components/header-searchtext'
  import APIMixin from '../mixins/api'
  import { saveAs } from 'file-saver'
  import sanitizeFilename from 'sanitize-filename'
  import { DateTime } from 'luxon'

  export default {
    components: {
      HeaderSelectGroup,
      HeaderSearchtext
    },
    mixins: [APIMixin],
    data() {
      return {
        results: [],
        columns: [
          {
            key: 'email',
            width: 320,
            sortable: true,
            title: this.$t('label.email')
          },
          {
            key: 'institution',
            width: 220,
            sortable: true,
            title: this.$t('label.institution'),
            render: (h, p) => h('span', p.row.institution || '-')
          },
          {
            key: 'firstname',
            width: 220,
            sortable: true,
            title: this.$t('label.firstname'),
            render: (h, p) => h('span', p.row.firstname || '-')
          },
          {
            key: 'lastname',
            width: 220,
            sortable: true,
            title: this.$t('label.lastname'),
            render: (h, p) => h('span', p.row.lastname || '-')
          },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ]
      }
    },
    computed: {
      is_admin() {
        return this.$store.state.auth.user.admin
      },
      searchtext: {
        get() {
          return this.$store.state.ui.customers_searchtext
        },
        set(v) {
          this.$store.commit('ui/setCustomersSearchtext', v)
        }
      },
      group: {
        get() {
          return this.$store.state.ui.customers_group
        },
        set(v) {
          this.$store.commit('ui/setCustomersGroup', v)
        }
      }
    },
    watch: {
      group() {
        this.refresh()
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      async refresh() {
        this.results = await this.apiGet('customers', {
          url: '/customers',
          params: {
            q: this.searchtext,
            group: this.group
          }
        })
      },
      open(customer) {
        this.$router.push({ name: 'customer', params: { id: customer._id } })
      },
      async download() {
        const res = await this.apiGet('customers', {
          url: '/customers',
          params: {
            q: this.searchtext,
            group: this.group,
            export: true
          },
          responseType: 'blob'
        })
        const fn = `${DateTime.local().toFormat(
          'yyyyMMdd_HHmmss'
        )}_Cardiolectra-Export_Customers.xlsx`
        saveAs(res, sanitizeFilename(fn))
      }
    }
  }
</script>
