const state = {
  customers_searchtext: '',
  customers_group: '',
  licenses_searchtext: '',
  licenses_group: '',
  orders_searchtext: '',
  orders_group: ''
}

const mutations = {
  setCustomersSearchtext(state, text) {
    state.customers_searchtext = text || ''
  },
  setCustomersGroup(state, group) {
    state.customers_group = group || ''
  },
  setLicensesSearchtext(state, text) {
    state.licenses_searchtext = text || ''
  },
  setLicensesGroup(state, group) {
    state.licenses_group = group || ''
  },
  setOrdersSearchtext(state, text) {
    state.orders_searchtext = text || ''
  },
  setOrdersGroup(state, group) {
    state.orders_group = group || ''
  }
}

const actions = {
  reset({ commit }) {
    commit('setCustomersSearchtext')
    commit('setCustomersGroup')
    commit('setLicensesSearchtext')
    commit('setLicensesGroup')
    commit('setOrdersSearchtext')
    commit('setOrdersGroup')
  }
}

export default { namespaced: true, state, mutations, actions }
