<template>
  <div class="app">
    <Sider v-if="user" class="sider">
      <div class="sider-logo">
        <img src="./assets/img/logo.png" height="32" />
        <span><b>CRM</b> v{{ version }}</span>
      </div>
      <Menu
        :active-name="$route.meta.nav || $route.name"
        theme="light"
        width="auto"
      >
        <MenuItem name="dashboard" :to="{ name: 'dashboard' }">
          <Icon type="md-desktop" />
          <span>{{ $t('label.dashboard') }}</span>
        </MenuItem>
        <MenuItem name="customers" :to="{ name: 'customers' }">
          <Icon type="md-people" />
          <span>{{ $t('label.customers') }}</span>
        </MenuItem>
        <MenuItem name="licenses" :to="{ name: 'licenses' }">
          <Icon type="md-paper" />
          <span>{{ $t('label.licenses') }}</span>
        </MenuItem>
        <!--
        <MenuItem name="support" :to="{ name: 'support' }">
          <Icon type="md-chatboxes"></Icon>
          <span>{{ $t('label.support') }}</span>
        </MenuItem>
        <MenuItem name="settings" :to="{ name: 'settings' }">
          <Icon type="md-settings"></Icon>
          <span>{{ $t('label.settings') }}</span>
        </MenuItem>
        -->
        <MenuItem name="statistics" :to="{ name: 'statistics' }">
          <Icon type="md-trending-up" />
          <span>{{ $t('label.statistics') }}</span>
        </MenuItem>
        <MenuItem name="orders" :to="{ name: 'orders' }">
          <Icon type="md-basket" />
          <span>{{ $t('label.orders') }}</span>
        </MenuItem>
        <MenuItem v-if="user.admin" name="groups" :to="{ name: 'groups' }">
          <Icon type="md-folder-open" />
          <span>{{ $t('label.groups') }}</span>
        </MenuItem>
        <MenuItem
          v-if="!user.admin"
          name="group"
          :to="{ name: 'group', params: { id: user.group } }"
        >
          <Icon type="md-folder-open" />
          <span>{{ $t('label.group') }}</span>
        </MenuItem>
        <MenuItem name="products" :to="{ name: 'products' }">
          <Icon type="md-photos" />
          <span>{{ $t('label.products') }}</span>
        </MenuItem>
        <MenuItem v-if="user.admin" name="users" :to="{ name: 'users' }">
          <Icon type="md-people" />
          <span>{{ $t('label.users') }}</span>
        </MenuItem>
        <MenuItem name="login" :to="{ name: 'login' }">
          <Icon type="md-log-out" />
          <span>{{ $t('action.logout') }}</span>
          <div style="font-size: 0.7em; font-style: italic;">
            {{ user_email }}
          </div>
        </MenuItem>
      </Menu>
    </Sider>
    <div class="app-view-wrap">
      <router-view />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    metaInfo: {
      meta: [{ name: 'version', content: process.env.PACKAGE_VERSION }]
    },
    computed: {
      version() {
        return process.env.PACKAGE_VERSION
      },
      user() {
        return this.$store.state.auth.user
      },
      user_email() {
        return this.user && _.truncate(this.user.email, { length: 32 })
      }
    },
    watch: {
      '$store.state.loading'() {
        this.updateLoading()
      }
    },
    mounted() {
      this.updateLoading()
    },
    methods: {
      updateLoading() {
        if (this.loading_timeout) {
          clearTimeout(this.loading_timeout)
          this.loading_timeout = null
        }
        if (this.$store.state.loading) {
          if (document.activeElement) {
            document.activeElement.blur()
          }
          if (!this.loading_visible) {
            this.$Spin.show()
            this.loading_visible = true
          }
        } else {
          this.loading_visible = false
          this.loading_timeout = setTimeout(() => {
            if (!this.loading_visible) {
              this.$Spin.hide()
            }
          }, 500)
        }
      }
    }
  }
</script>

<style lang="scss">
  html,
  body {
    height: 100%;
  }

  .app {
    min-height: 100%;
    display: flex;

    .sider {
      background-color: #fff;
      user-select: none;
    }

    .sider-logo {
      padding: 0.5em;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      font-size: 1.2em;

      img {
        margin-right: 1em;
      }
    }
  }

  .app-view-wrap {
    background-color: #f5f7f9;
    flex: 1;
    overflow-x: hidden;
  }
</style>
