<template>
  <ViewLayout icon="md-desktop" :title="$t('label.dashboard')">
    <table v-if="stats" class="indicators">
      <tr>
        <td class="label">{{ $t('dashboard.num-customers') }}:</td>
        <td class="value"><AnimatedNumber :number="stats.num_customers" /></td>
      </tr>
      <tr>
        <td class="label">{{ $t('dashboard.num-licenses') }}:</td>
        <td class="value"><AnimatedNumber :number="stats.num_licenses" /></td>
      </tr>
      <tr>
        <td class="label">{{ $t('dashboard.num-active-licenses') }}:</td>
        <td class="value">
          <AnimatedNumber :number="stats.num_active_licenses" />
        </td>
      </tr>
      <tr>
        <td class="label">{{ $t('dashboard.num-orders') }}:</td>
        <td class="value">
          <AnimatedNumber :number="stats.num_orders" />
        </td>
      </tr>
      <tr v-if="user.admin">
        <td class="label">{{ $t('dashboard.num-groups') }}:</td>
        <td class="value"><AnimatedNumber :number="stats.num_groups" /></td>
      </tr>
      <tr v-if="user.admin">
        <td class="label">{{ $t('dashboard.num-users') }}:</td>
        <td class="value"><AnimatedNumber :number="stats.num_users" /></td>
      </tr>
    </table>
  </ViewLayout>
</template>

<script>
  import APIMixin from '../mixins/api'
  import AnimatedNumber from '../components/animated-number'

  export default {
    components: {
      AnimatedNumber
    },
    mixins: [APIMixin],
    data() {
      return {
        stats: null
      }
    },
    computed: {
      user() {
        return this.$store.state.auth.user
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      refresh() {
        this.apiGet({ url: '/dashboard/stats' }).then((stats) => {
          this.stats = stats
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .indicators {
    font-size: 24px;
    margin-bottom: 4em;

    .label {
      font-weight: bold;
    }

    .value {
      padding-left: 0.5em;
    }
  }
</style>
