<template>
  <span>{{ displayNumber }}</span>
</template>

<script>
  export default {
    props: {
      number: {
        default: 0
      }
    },
    data() {
      return {
        displayNumber: 0,
        interval: false
      }
    },
    watch: {
      number() {
        this.reset()
      }
    },
    created() {
      this.reset()
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },
    methods: {
      reset() {
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = null
        }
        this.displayNumber = 0
        this.interval = setInterval(() => {
          if (this.displayNumber !== this.number) {
            var change = (this.number - this.displayNumber) / 10
            change = change >= 0 ? Math.ceil(change) : Math.floor(change)
            this.displayNumber = this.displayNumber + change
          } else {
            clearInterval(this.interval)
            this.interval = null
          }
        }, 100)
      }
    }
  }
</script>
