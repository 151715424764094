<template>
  <div style="width: 100%;">
    <Table :data="results" :columns="columns">
      <template v-if="!owner" slot="owner" slot-scope="{ row }">
        <router-link :to="{ name: 'customer', params: { id: row.owner._id } }">
          {{ row.owner.email || row.owner._id }}
        </router-link>
      </template>
      <template slot="group" slot-scope="{ row }">
        <router-link :to="{ name: 'group', params: { id: row.group } }">
          {{ $store.getters['groups/name_by_id'][row.group] || row.group }}
        </router-link>
      </template>
      <template slot="product" slot-scope="{ row }">
        <template v-if="is_admin">
          <router-link :to="{ name: 'product', params: { id: row.product } }">
            {{
              $store.getters['products/name_by_id'][row.product] || row.product
            }}
          </router-link>
        </template>
        <template v-else>
          <router-link :to="{ name: 'products' }">
            {{
              $store.getters['products/name_by_id'][row.product] || row.product
            }}
          </router-link>
        </template>
      </template>
      <template v-if="results.length" slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button
            icon="md-close-circle"
            :disabled="!!row.revoked"
            @click="revoke(row)"
          >
            {{ $t('action.revoke') }}
          </Button>
          <Button icon="md-add" :disabled="!!row.revoked" @click="extend(row)">
            {{ $t('action.extend') }}
          </Button>
          <Button
            icon="md-photos"
            :disabled="!!row.revoked"
            @click="change(row)"
          >
            {{ $t('label.product') }}
          </Button>
          <Button
            icon="md-desktop"
            :disabled="!row.devices"
            @click="resetDevices(row)"
          >
            {{ $t('action.reset') }}
          </Button>
          <Button
            v-if="is_admin"
            icon="md-folder-open"
            @click="changeGroup(row)"
          >
            {{ $t('label.group') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
    <Modal
      :value="!!extend_license"
      :title="$t('action.confirm')"
      :ok-text="$t('action.extend')"
      @on-ok="extendLicense"
      @on-visible-change="closeExtend"
    >
      <template v-if="extend_license">
        <p style="margin-bottom: 1em;">
          {{
            $t('licenses.ask-extend-days-message', { key: extend_license.key })
          }}
        </p>
        <InputNumber v-model="days_to_extend"></InputNumber>
      </template>
    </Modal>
    <Modal
      :value="!!change_license"
      :title="$t('label.license')"
      :ok-text="$t('action.save')"
      @on-ok="changeLicense"
      @on-visible-change="closeChange"
    >
      <Form v-if="change_license" :model="change_license" :label-width="120">
        <Row>
          <i-col span="24">
            <FormItem :label="$t('label.license-key')" prop="key">
              <i-input v-model.trim="change_license.key" disabled />
            </FormItem>
            <FormItem :label="$t('label.product')" prop="product">
              <Select v-model="change_license.product">
                <Option
                  v-for="p in $store.getters['products/for_user']"
                  :key="p._id"
                  :value="p._id"
                >
                  {{ p.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </Modal>
    <Modal
      :value="!!change_group"
      :title="$t('label.group')"
      :ok-text="$t('action.save')"
      @on-ok="changeLicenseGroup"
      @on-visible-change="closeChangeGroup"
    >
      <Form v-if="change_group" :model="change_group" :label-width="120">
        <Row>
          <i-col span="24">
            <FormItem :label="$t('label.license-key')" prop="key">
              <i-input v-model.trim="change_group.key" disabled />
            </FormItem>
            <FormItem :label="$t('label.product')" prop="product">
              <Select v-model="change_group.group">
                <Option
                  v-for="g in $store.state.groups.list"
                  :key="g._id"
                  :value="g._id"
                >
                  {{ g.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'
  import { DateTime } from 'luxon'
  import { saveAs } from 'file-saver'
  import sanitizeFilename from 'sanitize-filename'

  export default {
    mixins: [APIMixin],
    props: {
      owner: {
        type: String,
        default: ''
      },
      group: {
        type: String,
        default: ''
      },
      searchText: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        results: [],
        extend_license: null,
        days_to_extend: 1,
        change_license: null,
        change_group: null
      }
    },
    computed: {
      is_admin() {
        return this.$store.state.auth.user.admin
      },
      columns() {
        return _.compact([
          {
            key: 'key',
            width: 280,
            title: this.$t('label.license-key')
          },
          this.is_admin &&
            !this.group && {
              key: 'group',
              slot: 'group',
              width: 160,
              sortable: true,
              title: this.$t('label.group')
            },
          {
            key: 'product',
            slot: 'product',
            width: 180,
            sortable: true,
            title: this.$t('label.product')
          },
          {
            key: 'devices',
            width: 120,
            sortable: true,
            title: this.$t('label.devices')
          },
          {
            key: 'expires',
            width: 120,
            sortable: true,
            title: this.$t('label.expiry'),
            render(h, params) {
              if (params.row.revoked) {
                return h('span', '-')
              }
              let text = ''
              const d = DateTime.fromISO(params.row.expires).diff(
                DateTime.local().endOf('day')
              )
              if (d.milliseconds < 0) {
                text = `${d.negate().toFormat('d')} days ago` // FIXME: i18n
              } else {
                text = `in ${d.toFormat('d')} days` // FIXME: i18n
              }
              return h('span', text)
            }
          },
          {
            key: '_mode',
            width: 100,
            sortable: true,
            title: this.$t('label.mode'),
            render: (h, params) => {
              let mode = params.row._mode
              const k = `licenses.mode.${mode}`
              mode = this.$te(k) ? this.$t(k) : mode
              return h('span', mode)
            }
          },
          {
            key: 'status',
            width: 100,
            sortable: true,
            title: this.$t('label.status'),
            render: (h, params) => {
              let status = ''
              const d = DateTime.fromISO(params.row.expires).diff(
                DateTime.local().endOf('day')
              )
              if (params.row.revoked) {
                status = this.$t('licenses.status.revoked')
              } else if (d.milliseconds < 0) {
                status = this.$t('licenses.status.expired')
              } else {
                status = this.$t('licenses.status.valid')
              }
              return h('span', status)
            }
          },
          !this.owner && {
            key: 'owner',
            width: 200,
            title: this.$t('label.owner'),
            slot: 'owner'
          },
          {
            key: 'createdAt',
            width: 180,
            sortable: true,
            title: this.$t('label.created-at'),
            render(h, params) {
              const d = DateTime.fromISO(params.row.createdAt)
              return h('span', d.toFormat('D T'))
            }
          },
          {
            title: ' '
          },
          this.results.length && {
            title: ' ',
            width: 400,
            fixed: 'right',
            slot: 'actions'
          }
        ])
      }
    },
    watch: {
      group() {
        this.refresh()
      },
      searchText() {
        this.refresh()
      },
      owner() {
        this.refresh()
      }
    },
    mounted() {
      this.$store.dispatch('groups/refresh')
      this.$store.dispatch('products/refresh')
      this.refresh()
    },
    methods: {
      async refresh() {
        this.results = await this.apiGet('licenses', {
          url: '/licenses',
          params: {
            owner: this.owner,
            group: this.group,
            search: this.searchText
          }
        })
      },
      async export() {
        const res = await this.apiGet('export', {
          url: '/licenses',
          params: {
            owner: this.owner,
            group: this.group,
            search: this.searchText,
            export: true
          },
          responseType: 'blob'
        })

        const fn = `${DateTime.local().toFormat(
          'yyyyMMdd_HHmmss'
        )}_Cardiolectra-Export_Licenses.xlsx`
        saveAs(res, sanitizeFilename(fn))
      },
      revoke(license) {
        this.$Modal.confirm({
          title: this.$t('action.confirm'),
          content: this.$t('licenses.confirm-revoke-message', {
            key: license.key
          }),
          okText: this.$t('action.revoke'),
          onOk: () => {
            this.apiPost({ url: `/license/${license._id}/revoke` }).then(() => {
              this.refresh()
              this.$emit('set-search', license.key)
            })
          }
        })
      },
      extend(license) {
        this.extend_license = license
        this.days_to_extend = 1
      },
      resetDevices(license) {
        this.$Modal.confirm({
          title: this.$t('action.confirm'),
          content: this.$t('licenses.confirm-reset-devices', {
            key: license.key
          }),
          okText: this.$t('action.reset-devices'),
          onOk: () => {
            this.apiPost({
              url: `/license/${license._id}/reset-devices`
            }).then(() => this.refresh())
          }
        })
      },
      async extendLicense() {
        if (!this.days_to_extend) {
          return
        }
        const key = this.extend_license.key
        const data = { days: this.days_to_extend }
        await this.apiPost({
          url: `/license/${this.extend_license._id}/extend`,
          data
        })
        this.refresh()
        this.extend_license = null
        this.$emit('set-search', key)
      },
      closeExtend(v) {
        this.extend_license = !v ? null : this.extend_license
      },
      change(license) {
        this.$store.dispatch('products/refresh')
        this.change_license = license
      },
      async changeLicense() {
        const key = this.change_license.key
        await this.apiPut({
          url: `/license/${this.change_license._id}`,
          data: this.change_license
        })
        this.change_license = null
        this.refresh()
        this.$emit('set-search', key)
      },
      closeChange(v) {
        this.change_license = !v ? null : this.change_license
      },
      changeGroup(license) {
        //this.$store.dispatch('products/refresh')
        this.change_group = license
      },
      async changeLicenseGroup() {
        const key = this.change_group.key
        await this.apiPut({
          url: `/license/${this.change_group._id}`,
          data: this.change_group
        })
        this.change_group = null
        this.refresh()
        this.$emit('set-search', key)
      },
      closeChangeGroup(v) {
        this.change_group = !v ? null : this.change_group
      }
    }
  }
</script>

<style lang="scss" scoped></style>
