<template>
  <ViewLayout icon="md-folder-open" :title="$t('label.product')">
    <template #controls>
      <ButtonGroup>
        <Button
          :type="is_pristine ? 'default' : 'primary'"
          icon="md-cloud-upload"
          :disabled="!can_save"
          @click="save()"
        >
          {{ $t('action.save') }}
        </Button>
      </ButtonGroup>
    </template>
    <template v-if="product">
      <Form :model="product" :label-width="160">
        <Row>
          <i-col span="12">
            <FormItem :label="$t('label.name')" prop="name">
              <Input v-model.trim="product.name" readonly disabled />
            </FormItem>
            <FormItem :label="$t('label.tag')" prop="tag">
              <Input v-model.trim="product.tag" readonly disabled />
            </FormItem>
            <FormItem :label="$t('label.description')" prop="description">
              <Input
                v-model.trim="product.description"
                type="textarea"
                autosize
              />
            </FormItem>
            <FormItem :label="$t('label.duration-days')" prop="full_duration">
              <InputNumber
                v-model="product.full_duration"
                :precision="0"
                :min="0"
                style="width: 200px;"
              ></InputNumber>
            </FormItem>
            <FormItem :label="$t('label.price')" prop="price">
              <InputNumber
                v-model="product.price"
                :precision="2"
                :min="0"
                style="width: 200px;"
              ></InputNumber>
            </FormItem>
            <FormItem :label="$t('label.groups')">
              <div v-for="g in $store.state.groups.list" :key="g._id">
                <Checkbox
                  :value="group_checks[g._id]"
                  size="large"
                  @on-change="changeGroup(g._id, $event)"
                >
                  {{ g.name }}
                </Checkbox>
              </div>
            </FormItem>
            <FormItem>
              <Alert type="warning" show-icon>
                {{ $t('products.hint-group-assignment') }}
              </Alert>
            </FormItem>
          </i-col>
        </Row>
      </Form>
    </template>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'

  export default {
    mixins: [APIMixin],
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        product: null,
        pristine_hash: ''
      }
    },
    computed: {
      group_checks() {
        const ids = _.map(this.$store.state.groups.list, '_id')
        return _.zipObject(
          ids,
          _.map(ids, (id) => _.includes(this.product.groups, id))
        )
      },
      can_save() {
        return true
      },
      is_pristine() {
        return this.pristine_hash === this.$objectHash(this.product)
      }
    },
    mounted() {
      this.reload()
    },
    methods: {
      async reload() {
        this.$store.dispatch('groups/refresh')
        this.product = await this.apiGet({ url: `/product/${this.id || ''}` })
        this.pristine_hash = this.$objectHash(this.product)
      },
      async save() {
        await this.apiPut({ url: `/product`, data: this.product })
        this.$Notice.success({
          title: this.$t('label.product'),
          desc: this.$t('notice.save-success')
        })
        await this.reload()
      },
      changeGroup(id, v) {
        this.product.groups = _.without(this.product.groups, id)
        this.product.groups = v
          ? _.concat(this.product.groups, id)
          : this.product.groups
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.is_pristine) {
        return next()
      }
      this.$Modal.confirm({
        title: this.$t('action.confirm'),
        content: this.$t('confirm.unsaved-changes'),
        cancelText: this.$t('action.ignore'),
        onCancel: () => {
          next()
        },
        onOk: () => {
          next(false)
        }
      })
    }
  }
</script>

<style lang="scss" scoped></style>
