import _ from 'lodash'

const SLOT_DEFAULT = 'default'

export default {
  data() {
    return {
      api_requests: {
        _promises: {},
        num_pending: 0
      }
    }
  },
  beforeDestroy() {
    for (let slot in this.api_requests._promises) {
      if (this.api_requests._promises[slot]) {
        this.api_requests._promises[slot]()
        this.$set(this.api_requests._promises, slot, null)
      }
    }
  },
  methods: {
    _apiUpdateCounters() {
      this.api_requests.num_pending = _.size(
        _.compact(_.values(this.api_requests._promises))
      )
    },
    apiRequest(slot, opt) {
      if (_.isUndefined(opt)) {
        opt = slot
        slot = SLOT_DEFAULT
      }
      if (this.api_requests._promises[slot]) {
        _.defer(this.api_requests._promises[slot])
        this.$set(this.api_requests._promises, slot, null)
        this._apiUpdateCounters()
      }
      return new Promise((resolve, reject) => {
        let cancel = null
        opt.cancelToken = new this.$api.newCancelToken((c) => (cancel = c))
        this.$api
          ._request(opt, (c) => {
            cancel = c
          })
          .then((res) => {
            this.$set(this.api_requests._promises, slot, null)
            this._apiUpdateCounters()
            resolve(res)
          })
          .catch((err) => {
            this.$set(this.api_requests._promises, slot, null)
            this._apiUpdateCounters()
            if (this.$api.isCancelError(err)) {
              return
            }
            if (!opt.silent) {
              if (this.$reportError) {
                this.$reportError(err)
              } else {
                console.error(err)
              }
            }
            reject(err)
          })
        this.$set(this.api_requests._promises, slot, cancel)
        this._apiUpdateCounters()
      })
    },
    apiGet(slot, opt) {
      if (_.isUndefined(opt)) {
        opt = slot
        slot = SLOT_DEFAULT
      }
      opt = _.isString(opt) ? { path: opt } : opt
      opt.method = 'get'
      return this.apiRequest(slot, opt)
    },
    apiPut(slot, opt) {
      if (_.isUndefined(opt)) {
        opt = slot
        slot = SLOT_DEFAULT
      }
      opt = _.isString(opt) ? { path: opt } : opt
      opt.method = 'put'
      return this.apiRequest(slot, opt)
    },
    apiPost(slot, opt) {
      if (_.isUndefined(opt)) {
        opt = slot
        slot = SLOT_DEFAULT
      }
      opt = _.isString(opt) ? { path: opt } : opt
      opt.method = 'post'
      return this.apiRequest(slot, opt)
    },
    apiDel(slot, opt) {
      if (_.isUndefined(opt)) {
        opt = slot
        slot = SLOT_DEFAULT
      }
      opt = _.isString(opt) ? { path: opt } : opt
      opt.method = 'delete'
      return this.apiRequest(slot, opt)
    }
  }
}
