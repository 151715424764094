import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth'
import products from './products'
import groups from './groups'
import ui from './ui'

const store = new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    setLoading(state, on) {
      state.loading = !!on
    }
  },
  modules: {
    auth,
    products,
    groups,
    ui
  },
  strict:
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing'
})

export default store
