<template>
  <ViewLayout icon="md-folder-open" :title="$t('label.group')">
    <template #controls>
      <ButtonGroup>
        <Button
          :type="is_pristine ? 'default' : 'primary'"
          icon="md-cloud-upload"
          :disabled="!can_save"
          @click="save()"
        >
          {{ $t('action.save') }}
        </Button>
        <Button
          v-if="is_admin && !is_creating"
          type="error"
          icon="md-trash"
          ghost
          @click="remove()"
        >
          {{ $t('action.delete') }}
        </Button>
      </ButtonGroup>
    </template>
    <template v-if="group">
      <Form :model="group" :label-width="240">
        <Row>
          <i-col span="12">
            <FormItem :label="$t('label.name')" prop="email">
              <i-input v-model.trim="group.name" :disabled="!is_admin" />
            </FormItem>
            <FormItem :label="$t('label.description')">
              <i-input
                v-model.trim="group.description"
                type="textarea"
                :disabled="!is_admin"
              />
            </FormItem>
            <FormItem :label="$t('label.request-code')">
              <i-input
                v-model.trim="group.request_code"
                :disabled="!is_creating || !is_admin"
              />
            </FormItem>
            <FormItem>
              <Alert type="warning" show-icon>
                {{ $t('groups.hint-unchangeable-request-code') }}
              </Alert>
            </FormItem>
            <FormItem :label="$t('groups.initial-license-duration')">
              <InputNumber
                v-model="group.initial_license_duration"
                :disabled="!is_admin"
              ></InputNumber>
            </FormItem>
            <FormItem
              :label="$t('groups.initial-license-product')"
              prop="group"
            >
              <Select
                v-model="group.initial_license_product"
                :disabled="!is_admin"
              >
                <Option value="">&mdash;</Option>
                <Option
                  v-for="p in $store.getters['products/for_user']"
                  :key="p._id"
                  :value="p._id"
                >
                  {{ p.name }}
                </Option>
              </Select>
            </FormItem>
            <template v-if="!is_creating">
              <h2>{{ $t('groups.product-discounts') }}</h2>
              <template v-for="p in group_products">
                <FormItem :key="p._id" :label="p.name">
                  <InputNumber
                    :value="discounts[p._id]"
                    :precision="0"
                    :min="0"
                    @on-change="changeDiscount(p._id, $event)"
                  ></InputNumber>
                </FormItem>
              </template>
            </template>
          </i-col>
        </Row>
      </Form>
    </template>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'
  import CommonMixin from '../mixins/common'

  export default {
    mixins: [APIMixin, CommonMixin],
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        group: null,
        pristine_hash: ''
      }
    },
    computed: {
      is_creating() {
        return !this.id
      },
      can_save() {
        return (
          this.group &&
          _.trim(this.group.name) &&
          _.trim(this.group.request_code) &&
          this.group.initial_license_duration > 3 &&
          this.group.initial_license_product
        )
      },
      discounts() {
        const ids = _.map(this.$store.getters['products/for_user'], '_id')
        return _.zipObject(
          ids,
          _.map(ids, (id) => this.group.discounts[id] || 0)
        )
      },
      group_products() {
        const products = this.$store.state.products.list
        return _.filter(products, (p) => _.includes(p.groups, this.group._id))
      },
      is_pristine() {
        return this.pristine_hash === this.$objectHash(this.group)
      }
    },
    mounted() {
      this.reload()
    },
    methods: {
      async reload() {
        this.$store.dispatch('products/refresh')
        this.group = await this.apiGet('group', {
          url: `/group/${this.id || ''}`
        })
        this.pristine_hash = this.$objectHash(this.group)
      },
      async save() {
        const res = await this.apiPut({ url: `/group`, data: this.group })
        this.$Notice.success({
          title: this.$t('label.group'),
          desc: this.$t('notice.save-success')
        })
        if (this.is_creating) {
          this.$router.replace({ name: 'group', params: { id: res._id } })
        } else {
          this.reload()
        }
      },
      remove() {
        this.$Modal.confirm({
          title: this.$t('action.confirm'),
          content: this.$t('groups.confirm-delete-message'),
          onOk: () => {
            this.apiDel({ url: `/group/${this.group._id}` }).then(() => {
              this.$router.replace({ name: 'groups' })
            })
          }
        })
      },
      changeDiscount(pid, val) {
        this.group.discounts[pid] = val
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.is_pristine) {
        return next()
      }
      this.$Modal.confirm({
        title: this.$t('action.confirm'),
        content: this.$t('confirm.unsaved-changes'),
        cancelText: this.$t('action.ignore'),
        onCancel: () => {
          next()
        },
        onOk: () => {
          next(false)
        }
      })
    }
  }
</script>

<style lang="scss" scoped></style>
