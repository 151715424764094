<template>
  <ViewLayout icon="md-people" :title="$t('label.users')">
    <template #controls>
      <ButtonGroup>
        <Button type="primary" icon="md-refresh" ghost @click="refresh">
          {{ $t('action.refresh') }}
        </Button>
        <Button type="primary" icon="md-add" @click="createUser">
          {{ $t('action.create') }}
        </Button>
      </ButtonGroup>
    </template>

    <Table :data="results" :columns="columns">
      <template slot="group" slot-scope="{ row }">
        <router-link :to="{ name: 'group', params: { id: row.group } }">
          {{ groupNames[row.group] || row.group }}
        </router-link>
      </template>
      <template slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button icon="md-folder-open" @click="open(row)">
            {{ $t('action.edit') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'

  export default {
    mixins: [APIMixin],
    data() {
      return {
        groups: {},
        results: [],
        columns: [
          {
            key: 'email',
            width: 320,
            sortable: true,
            title: this.$t('label.email')
          },
          {
            slot: 'group',
            key: 'group',
            width: 320,
            sortable: true,
            title: this.$t('label.group')
          },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ]
      }
    },
    computed: {
      groupNames() {
        return _.mapValues(_.keyBy(this.groups, '_id'), 'name')
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      async refresh() {
        this.results = []
        const groups = await this.apiGet('groups', { url: '/groups' })
        this.groups = {}
        for (const g of groups) {
          this.groups[g._id] = g
        }
        this.results = await this.apiGet('users', { url: '/users' })
      },
      open(user) {
        this.$router.push({ name: 'user', params: { id: user._id } })
      },
      createUser() {
        this.$router.push({ name: 'user' })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
