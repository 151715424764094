<template>
  <ViewLayout icon="md-trending-up" :title="$t('label.statistics')">
    <template #controls>
      <header-select-group
        v-if="is_admin"
        v-model="group"
      ></header-select-group>
    </template>
    <h1>{{ $t('statistics.app-starts') }}</h1>
    <div class="controls">
      <DatePicker
        type="daterange"
        format="dd.MM.yyyy"
        :placeholder="$t('action.select-daterange')"
        placement="bottom-start"
        clearable
        split-panels
        style="width: 300px;"
        @on-change="onSetRange"
      ></DatePicker>
      <Button icon="md-refresh" @click="refresh">
        {{ $t('action.refresh') }}
      </Button>
    </div>
    <div class="row">
      <div class="chart">
        <h2>{{ $t('label.platform') }}</h2>
        <PieChart v-if="platform_data" :chart-data="platform_data"></PieChart>
      </div>
      <div class="chart">
        <h2>{{ $t('label.language') }}</h2>
        <PieChart v-if="language_data" :chart-data="language_data"></PieChart>
      </div>
      <div class="chart">
        <h2>{{ $t('label.version') }}</h2>
        <PieChart v-if="version_data" :chart-data="version_data"></PieChart>
      </div>
    </div>
    <div class="chart">
      <h2>{{ $t('label.customer') }}</h2>
      <!--
      <PieChart v-if="customer_data" :chart-data="customer_data" :config="customer_config" @data-clicked="onCustomerClick"></PieChart>
      -->
      <Table :data="customer_data" :columns="customer_columns">
        <template slot="customer" slot-scope="{ row }">
          <span v-if="!row.customer_id">&mdash;</span>
          <router-link
            v-else
            :to="{ name: 'customer', params: { id: row.customer_id } }"
          >
            {{ row.customer_label }}
          </router-link>
        </template>
      </Table>
    </div>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import HeaderSelectGroup from '../components/header-select-group'
  import APIMixin from '../mixins/api'
  import { Pie, mixins } from 'vue-chartjs'
  import distinctColors from 'distinct-colors'
  import Chart from 'chart.js'
  import ChartDataLabels from 'chartjs-plugin-datalabels'
  Chart.plugins.unregister(ChartDataLabels)

  Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
    color: '#000',
    clamp: true,
    anchor: 'center',
    align: 'end',
    clip: false
  })

  const PieChart = {
    extends: Pie,
    mixins: [mixins.reactiveProp],
    props: ['chartData', 'config'],
    data() {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          events: ['click'],
          onClick: (_evt, elem) => {
            elem = _.first(elem)
            if (elem && _.isFinite(elem._index)) {
              this.$emit('data-clicked', elem._index)
            }
          },
          legend: Chart.helpers.merge(
            (this.config && this.config.legend) || {},
            {
              /*
            onClick: (e) => {
              e.stopPropagation()
            }
            */
            }
          )
        }
      }
    },
    mounted() {
      this.addPlugin(ChartDataLabels)
      this.rerender()
    },
    methods: {
      rerender() {
        this.renderChart(this.chartData, this.options)
      }
    }
  }

  function buildData(result, key) {
    const labels = _.keys(result[key])
    const data = _.map(labels, (l) => result[key][l])
    const backgroundColor = distinctColors({
      count: data.length,
      lightMin: 70
      //lightMax: 100
    }).map((c) => c.hex())
    return {
      labels,
      datasets: [
        {
          backgroundColor,
          data
        }
      ]
    }
  }

  export default {
    components: {
      PieChart,
      HeaderSelectGroup
    },
    mixins: [APIMixin],
    data() {
      return {
        group: '',
        license_customer_map: {},
        starts: {
          begin: '',
          end: '',
          result: null
        },
        customer_data: [],
        customer_columns: [
          {
            key: 'key',
            sortable: true,
            title: this.$t('label.license-key')
          },
          {
            key: 'starts',
            sortable: true,
            title: this.$t('statistics.app-starts')
          },
          {
            slot: 'customer',
            key: 'customer_label',
            sortable: true,
            title: this.$t('label.customer')
          }
        ]
      }
    },
    computed: {
      is_admin() {
        return this.$store.state.auth.user.admin
      },
      platform_data() {
        return this.starts.result
          ? buildData(this.starts.result, 'platform')
          : null
      },
      language_data() {
        return this.starts.result
          ? buildData(this.starts.result, 'language')
          : null
      },
      version_data() {
        return this.starts.result
          ? buildData(this.starts.result, 'version')
          : null
      },
      /*
      customer_data() {
        if (!this.starts.result) {
          return null
        }
        const keys = _.keys(this.starts.result.key)
        const labels = _.map(keys, (k) => _.get(this.license_customer_map, `${k}.email`, k))
        const data = _.map(keys, (k) => this.starts.result.key[k])
        const backgroundColor = distinctColors({
          count: data.length,
          lightMin: 70,
          //lightMax: 100
        }).map((c) => c.hex())
        return {
          labels,
          datasets: [
            {
              backgroundColor,
              data
            }
          ]
        }
      },
      */
      customer_config() {
        return {
          legend: {
            position: 'left'
          }
        }
      }
    },
    watch: {
      group() {
        this.refresh()
      }
    },
    async mounted() {
      this.license_customer_map = await this.apiGet('lcmap', {
        url: '/license-customer-map'
      })
      this.refresh()
    },
    methods: {
      refresh() {
        const data = {
          group: this.group,
          section: 'app-start',
          begin: this.starts.begin,
          end: this.starts.end
        }
        this.apiPost('starts', { url: '/statistics/check-license', data }).then(
          (result) => {
            this.starts.result = result
            this.rebuildCustomerData()
          }
        )
      },
      onSetRange(d) {
        this.starts.begin = d[0]
        this.starts.end = d[1]
        this.refresh()
      },
      onCustomerClick(idx) {
        const k = _.keys(this.starts.result.key)[idx]
        const c = k && this.license_customer_map[k]
        if (c && c._id) {
          this.$router.push({ name: 'customer', params: { id: c._id } })
        }
      },
      rebuildCustomerData() {
        if (!this.starts.result) {
          this.customer_data = []
        }
        let list = []
        for (const key in this.starts.result.key) {
          const customer = _.get(this.license_customer_map, key)
          list.push({
            key,
            starts: this.starts.result.key[key],
            customer_label: (customer && customer.email) || '',
            customer_id: customer && customer._id
          })
        }
        this.customer_data = _.reverse(_.sortBy(list, 'starts'))
      }
    }
  }
</script>

<style lang="scss" scoped>
  h1 {
    margin-bottom: 1rem;
  }

  .controls {
    margin-bottom: 1rem;
  }

  .row {
    display: flex;
    margin-bottom: 3rem;
  }

  .chart {
    margin-right: 1rem;

    h2 {
      line-height: 200%;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgb(177, 177, 177);
    }
  }
</style>
