<template>
  <ViewLayout icon="md-basket" :title="$t('label.order')">
    <template v-if="order">
      <Form :model="order" :label-width="160">
        <Row>
          <i-col span="8">
            <FormItem :label="$t('label.order-number')" prop="email">
              <i-input :value="order.number" readonly />
            </FormItem>
            <FormItem :label="$t('label.payment-method')" prop="method">
              <i-input :value="order.method" readonly />
            </FormItem>
            <template v-if="order.method === 'paypal'">
              <FormItem
                :label="$t('label.paypal-transaction-id')"
                prop="paypal_transaction.id"
              >
                <i-input :value="order.paypal_transaction.id" readonly />
              </FormItem>
            </template>
            <FormItem :label="$t('label.customer')">
              <router-link
                :to="{ name: 'customer', params: { id: order.customer._id } }"
              >
                {{ order.customer.email || order.customer._id }}
              </router-link>
            </FormItem>
            <FormItem v-if="is_admin" :label="$t('label.group')">
              <router-link
                :to="{ name: 'group', params: { id: order.group._id } }"
              >
                {{
                  ($store.getters['groups/name_by_id'][order.group] ||
                    order.group)
                }}
              </router-link>
            </FormItem>
            <FormItem :label="$t('label.ordered')">
              <i-input :value="formatTimestamp(order.ordered)" readonly />
            </FormItem>
            <FormItem :label="$t('label.note')">
              <i-input type="textarea" :value="order.note || '-'" readonly />
            </FormItem>

            <FormItem :label="$t('label.paid')">
              <i-input
                :value="order.paid ? formatTimestamp(order.paid) : '-'"
                readonly
              />
            </FormItem>

            <template v-if="order.method === 'invoice'">
              <FormItem :label="$t('label.bill-number')">
                <i-input v-model="order.bill" :readonly="!!order.paid" />
              </FormItem>
              <FormItem>
                <Button
                  type="success"
                  icon="md-card"
                  :disabled="!can_save || !order.bill"
                  @click="markPaid()"
                >
                  {{ $t('action.mark-paid') }}
                </Button>
              </FormItem>
            </template>

            <!--
            <FormItem :label="$t('label.data')">
              <Input :value="JSON.stringify(order.data)" type="textarea" autosize readonly />
            </FormItem>
            -->
          </i-col>
          <i-col span="14" offset="1">
            <h2>{{ $t('label.products') }}</h2>
            <i-table :data="order.items" :columns="item_columns">
              <template slot="product" slot-scope="{ row }">
                <template v-if="is_admin">
                  <router-link
                    :to="{ name: 'product', params: { id: row.product } }"
                  >
                    {{
                      ($store.getters['products/name_by_id'][row.product] ||
                        row.product)
                    }}
                  </router-link>
                </template>
                <template v-else>
                  <router-link :to="{ name: 'products' }">
                    {{
                      ($store.getters['products/name_by_id'][row.product] ||
                        row.product)
                    }}
                  </router-link>
                </template>
              </template>
            </i-table>
            <h2 style="margin-top: 2rem;">{{ $t('label.licenses') }}</h2>
            <ul style="margin-left: 1rem;">
              <li v-for="l in licenses" :key="l._id">
                <a href="#" @click.prevent="gotoLicense(l)">
                  {{ l.key }}
                </a>
              </li>
            </ul>
          </i-col>
        </Row>
      </Form>
    </template>
  </ViewLayout>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '../mixins/api'
  import CommonMixin from '../mixins/common'
  import { DateTime } from 'luxon'
  import { toEur } from '../../common/utils'

  export default {
    mixins: [APIMixin, CommonMixin],
    props: ['id'],
    data() {
      return {
        order: null,
        licenses: []
      }
    },
    computed: {
      can_save() {
        return this.order && !this.order.paid
      },
      item_columns() {
        return _.compact([
          {
            key: 'count',
            width: 100,
            sortable: true,
            title: this.$t('label.count')
          },
          {
            slot: 'product',
            key: 'product',
            width: 200,
            sortable: true,
            title: this.$t('label.product')
          },
          {
            key: 'price',
            width: 120,
            sortable: true,
            title: this.$t('label.price'),
            render(h, params) {
              return h('span', toEur(params.row.price).format())
            }
          },
          {
            key: 'discount',
            sortable: true,
            width: 120,
            title: this.$t('label.discount'),
            render(h, params) {
              return h('span', `${params.row.discount}%`)
            }
          },
          {
            title: this.$t('label.total'),
            render(h, params) {
              const d = toEur(params.row.price).multiply(
                params.row.discount / 100
              )
              const t = toEur(params.row.price)
                .multiply(params.row.count)
                .subtract(d)
              return h('span', t.format())
            }
          },
          {
            title: ' '
          }
        ])
      }
    },
    mounted() {
      this.$store.dispatch('groups/refresh')
      this.$store.dispatch('products/refresh')
      this.reload()
    },
    methods: {
      async reload() {
        this.order = await this.apiGet('order', {
          url: `/order/${this.id || ''}`
        })
        this.licenses = []
        for (const i of this.order.items) {
          for (const l of i.licenses) {
            const lic = await this.apiGet('lic', { url: `/license/${l}` })
            this.licenses.push(lic)
          }
        }
      },
      async markPaid() {
        await this.apiPost('mark-paid', {
          url: `/order/${this.id}/mark-paid`,
          data: this.order
        })
        await this.reload()
      },
      formatTimestamp(d) {
        return DateTime.fromISO(d).toFormat('dd.MM.yyyy HH:mm:ss')
      },
      gotoLicense(l) {
        this.$store.commit('ui/setLicensesGroup', l.group)
        this.$store.commit('ui/setLicensesSearchtext', l.key)
        this.$router.push({
          name: 'licenses'
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
