<template>
  <ViewLayout icon="md-folder-open" :title="$t('label.products')">
    <template #controls>
      <Button type="primary" icon="md-refresh" ghost @click="refresh">
        {{ $t('action.refresh') }}
      </Button>
    </template>

    <Table :data="$store.getters['products/for_user']" :columns="columns">
      <template v-if="is_admin" slot="actions" slot-scope="{ row }">
        <ButtonGroup size="small">
          <Button icon="md-folder-open" @click="open(row)">
            {{ $t('action.edit') }}
          </Button>
        </ButtonGroup>
      </template>
    </Table>
  </ViewLayout>
</template>

<script>
  import APIMixin from '../mixins/api'
  import CommonMixin from '../mixins/common'

  export default {
    mixins: [APIMixin, CommonMixin],
    data() {
      return {
        columns: [
          {
            key: 'name',
            width: 320,
            sortable: true,
            title: this.$t('label.name')
          },
          {
            key: 'tag',
            width: 320,
            sortable: true,
            title: this.$t('label.tag')
          },
          {
            key: 'price',
            width: 160,
            sortable: true,
            title: this.$t('label.price')
          },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ]
      }
    },
    mounted() {
      this.refresh()
    },
    methods: {
      async refresh() {
        this.$store.dispatch('products/refresh')
      },
      open(product) {
        this.$router.push({ name: 'product', params: { id: product._id } })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
