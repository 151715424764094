import _ from 'lodash'

const state = {
  list: []
}

const mutations = {
  setList(state, list) {
    state.list = list || []
  }
}

const getters = {
  by_id(state) {
    return _.keyBy(state.list, '_id')
  },
  name_by_id(state) {
    return _.mapValues(_.keyBy(state.list, '_id'), 'name')
  }
}

const actions = {
  async refresh({ commit }) {
    const list = await this._vm.$api.get('/groups')
    commit('setList', list)
  }
}

export default { namespaced: true, state, mutations, actions, getters }
