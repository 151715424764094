<template>
  <div class="view-login">
    <Card>
      <div slot="title" class="title">
        <img src="./../assets/img/logo.png" height="32" />
        <img src="./../assets/img/logo-text.png" height="14" />
        <span>CRM</span>
      </div>
      <Form ref="form" :model="form" :rules="rules">
        <FormItem prop="username">
          <i-input
            v-model="form.username"
            type="text"
            :placeholder="$t('label.username')"
            @on-keyup.enter="handleLogin"
          >
            <Icon slot="prepend" type="md-person" />
          </i-input>
        </FormItem>
        <FormItem prop="password">
          <i-input
            v-model="form.password"
            type="password"
            :placeholder="$t('label.password')"
            @on-keyup.enter="handleLogin"
          >
            <Icon slot="prepend" type="md-lock" />
          </i-input>
        </FormItem>
        <FormItem>
          <Alert v-if="error" type="error" show-icon>
            {{ $t('label.error') }}:
            <span slot="desc">{{ error }}</span>
          </Alert>
          <Button
            type="primary"
            icon="md-log-in"
            :disabled="!complete"
            @click="handleLogin"
          >
            {{ $t('action.login') }}
          </Button>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    data() {
      return {
        error: '',
        form: {
          username: '',
          password: ''
        },
        rules: {
          user: [
            {
              required: true,
              message: this.$t('login.hint.missing-username'),
              trigger: 'blur'
            }
          ],
          password: [
            {
              required: true,
              message: this.$t('login.hint.missing-password'),
              trigger: 'blur'
            },
            {
              type: 'string',
              min: 8,
              message: this.$t('login.hint.min-password-length', { length: 8 }),
              trigger: 'blur'
            }
          ]
        }
      }
    },
    computed: {
      complete() {
        return !!(_.trim(this.form.username) && _.trim(this.form.password))
      }
    },
    mounted() {
      this.$api.logout()
      this.$store.commit('auth/setUser', null)
    },
    methods: {
      handleLogin() {
        this.$refs.form.validate((v) => {
          if (!v) {
            return
          }
          this.$api
            .login(this.form)
            .then((user) => {
              this.$store.commit('auth/setUser', user)
              this.$store.dispatch('ui/reset')
              this.$router.replace(this.$route.query.redirect || '/')
            })
            .catch((err) => {
              console.error(err)
              this.error = this.$t(
                `login.error.${err.message || 'unknown-error'}`
              )
              this.form.password = ''
            })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .view-login {
    width: 400px;
    padding-top: 60px;
    margin: 0 auto;
  }

  .title {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
</style>
